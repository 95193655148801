<script>
export default {
  metaInfo: {
    title: "Dashboard",
  },
};
</script>

<script setup>
import DynamicDashboardView from '@/components/dynamic/DashboardView.vue';
</script>

<template>
  <DynamicDashboardView />
</template>



export const LIST_TYPES = {
  BLACK: {
    text: "Black List",
    value: "blacklist",
  },
  WHITE: {
    text: "White List",
    value: "whitelist",
  },
}



export function useBlackWhiteFiltersList(dashboardToEdit) {
  const createFullFilterConfig = (fName, listType = "", values = []) => {
    if (!listType) {
      return {
        key: fName,
      }
    }
    return {
      key: fName,
      [listType]: values,
    }
  }
  const isBlackList = fConfig => Boolean(fConfig[LIST_TYPES.BLACK.value])
  const isWhiteList = fConfig => !isBlackList(fConfig)

  const getFilterName = (f) => f?.key || f
  const getFilterConfigByName = (fName, fConfigList) => {
    let config = fConfigList.find(f => getFilterName(f) === fName)
    if (!config) {
      return
    }
    // eslint-disable-next-line no-undef
    return structuredClone(config)
  }
  const getNumberOfItemsInList = (fConfig) => {
    return fConfig[LIST_TYPES.BLACK.value]?.length || fConfig[LIST_TYPES.WHITE.value]?.length || 0
  }
  const getListName = (fConfig) => {
    if (isBlackList(fConfig)) {
      return LIST_TYPES.BLACK.text
    }
    if (isWhiteList(fConfig)) {
      return LIST_TYPES.WHITE.text
    }
    return ""
  }

  // should be done on the BACKEND side
  const adaptFiltersToOldApi = (filters) => {
    return filters.map((f) => {
      if (typeof f === 'string') {
        return f;
      }
      return f.key;
    });
  }

  // should be done on the BACKEND side
  const adaptFiltersToNewApi = (filters) => {
    return filters.map((f) => {
      if (typeof f === 'string') {
        return {
          key: f,
        };
      }
      return f;
    });
  }

  const constrainFilterValues = (fName, fValues) => {
    const doNotConstrain = !dashboardToEdit.value?.filters ||
      !dashboardToEdit.value.filters.length ||
      !fValues.length ||
      !fValues ||
      dashboardToEdit.value.filters.every(f => typeof f === 'string')

    if (doNotConstrain) return fValues

    const filterConfig = getFilterConfigByName(fName, dashboardToEdit.value.filters)

    if (!filterConfig) {
      return
    }

    // black list values
    if (isBlackList(filterConfig)) {
      return fValues.filter(v => !filterConfig[LIST_TYPES.BLACK.value]?.includes(v))
    }

    // white list values
    const wl = fValues.filter(v => filterConfig[LIST_TYPES.WHITE.value]?.includes(v))

    if (wl.length) {
      return wl
    }

    // default list values
    return fValues
  }

  return {
    constrainFilterValues,
    getFilterName,
    getFilterConfigByName,
    getNumberOfItemsInList,
    getListName,
    adaptFiltersToOldApi,
    adaptFiltersToNewApi,
    createFullFilterConfig,
    isBlackList,
    isWhiteList,
  }
}

